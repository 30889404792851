















































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { IUser } from '@/types/account';
import { equals, get } from 'lodash/fp';
import { upgradeAccount } from '@/api/account';

@Component
export default class TariffItem extends Vue {
  @Getter('account/user') user: IUser;
  @Action('global/closeUpgrade') closeUpgrade: () => void;
  @Prop() name: string;
  @Prop() planId: number;
  @Prop(Array) features: string[];
  @Prop({ default: true }) showCloseButton: boolean;

  isLoading: boolean = false;
  get isActive() {
    return equals(this.planId, this.userPlan);
  }

  get userPlan(): number {
    return get('plan.id', this.user);
  }

  getImgUrl(img: string) {
    return require('./img/' + img)
  }

  async upgrade() {
    this.isLoading = true;
    const { data } = await upgradeAccount(this.planId);
    const { data: jsonData } = data;
    const { attributes: { redirect_url } } = jsonData;
    window.location.href = redirect_url;
  }
}
