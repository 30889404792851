import Vue from 'vue';
import Router from 'vue-router';
import Dashboard from './views/Dashboard.vue';
Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'dashboard',
      component: Dashboard,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/billing',
      name: 'billing',
      component: () => import('./views/Billing.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('./views/About.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/support',
      name: 'support',
      component: () => import('./views/Support.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/accounts',
      name: 'accounts',
      component: () => import('./views/Accounts.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/lists',
      name: 'lists',
      component: () => import('./views/WhiteBlackLists.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/faq',
      name: 'faq',
      component: () => import('./views/FAQ.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('./views/Login.vue')
    },
    {
      path: '/code',
      name: 'code',
      component: () => import('./views/Code.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/two_factor',
      name: 'two_factor',
      component: () => import('./views/TwoFactor.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/new',
      name: 'new',
      component: () => import('./views/New.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/edit/:id',
      name: 'edit',
      component: () => import('./views/Edit.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/new/story_view',
      name: 'story_view',
      component: () => import('./views/StoryView.vue'),
      meta: {
        requiresAuth: true
      }
    },    
    {
      path: '/new/follow_like',
      name: 'follow_like',
      component: () => import('./views/FollowLike.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/new/unfollow',
      name: 'unfollow',
      component: () => import('./views/Unfollow.vue'),
      meta: {
        requiresAuth: true
      }
    }
  ]
});
export default router;
