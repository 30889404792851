import { Module } from 'vuex';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import { IRootState } from '@/store/types';

import { ITasksState } from './types';

const state: ITasksState = {
  tasks: [],
  isLoading: false,
  isAdding: false,
  task: null,
  isTaskFetching: false,
  isTaskSaving: false,
};

const account: Module<ITasksState, IRootState> = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};

export default account;
