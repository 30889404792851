import { Component, Prop, Vue } from 'vue-property-decorator';
import { without, includes, isArray } from 'lodash/fp';
import { Action, Getter } from 'vuex-class';

@Component
class UnfollowOptions extends Vue {
  @Getter('account/planId') planId: number;
  @Action('global/openUpgrade') openUpgrade: (startPlan: number) => void;
  @Prop() short: boolean;
  @Prop() options: string | string[];
  @Prop() disabled: boolean;

  modelOptions: string = '';
  prevChecked: string = '';
  autoStart: boolean = false;

  onClick(val: string) {
    if (val === this.prevChecked) {
      setTimeout(() => {
        this.modelOptions = '';
        this.prevChecked = '';
      }, 50);
      this.$emit('change', { options: [] });
    }
     else {
      setTimeout(() => {
        this.prevChecked = this.modelOptions;
      }, 50)
    }
  }

  onChange() {
    const options = this.modelOptions ? [this.modelOptions] : [];
    if (this.autoStart) {
      options.push('auto_start')
    }
    this.$emit('change', { options });
  }

  created() {
    this.autoStart = isArray(this.options) ? includes('auto_start', this.options) : false;
    this.modelOptions = isArray(this.options) ? without(['auto_start'], this.options)[0] : this.options;
    this.prevChecked = this.modelOptions;
  }

  isDisabled(value: string) {
    switch (value) {
      case 'autoStart':
        return this.planId < 4;
      default:
        return  false;
    }
  }
}

export default UnfollowOptions;
