import { render, staticRenderFns } from "./UserList.vue?vue&type=template&id=b3fec734&scoped=true&"
import script from "./UserList.ts?vue&type=script&lang=ts&"
export * from "./UserList.ts?vue&type=script&lang=ts&"
import style0 from "./styles.scss?vue&type=style&index=0&id=b3fec734&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b3fec734",
  null
  
)

export default component.exports