import { Component, Prop, Vue } from 'vue-property-decorator';
import Checkbox from '../UI/Checkbox/Checkbox';
import TagsInput from '../TagsInput/TagsInput';
import { EditableTask } from '@/types/tasks';

@Component({
  components: {
    Checkbox,
    TagsInput
  }
})
class Filters extends Vue {
  @Prop() task: EditableTask;
  @Prop() onFiltersChange: EditableTask;
  @Prop() onFiltersInput: EditableTask;
  @Prop() onCustomFilterChanged: EditableTask;
  @Prop() clearErrors: [];
}

export default Filters;
