import { jsonApi } from './config';
import { STask } from '@/types/tasks';

jsonApi.define('task', {
  accountId: '',
  following: '',
  actions: '',
  countTotalView: '',
  countTotalLike: '',
  countTotalFollow: '',
  countTotalUnfollow: '',
  customFilter: '',
  filters: '',
  options: '',
  targetText: '',
  target: '',
  tags: '',
  users: '',
  locations: '',
  results: '',
  status: '',
  description: ''
});


export function getTasks(): Promise<any> {
  return jsonApi.findAll('task');
}

export function addTask(data: STask): Promise<any> {
  return jsonApi.create('task', data);
}

export function getTask(id: string): Promise<any> {
  return jsonApi.find('task', id);
}

export function updateTask(task: STask): Promise<any> {
  return jsonApi.update('task', task);
}

export function deleteTask(id: number): Promise<any> {
  return jsonApi.destroy('task', id);
}
