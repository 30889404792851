import { render, staticRenderFns } from "./TagsInput.vue?vue&type=template&id=90c36242&scoped=true&"
import script from "./TagsInput.ts?vue&type=script&lang=ts&"
export * from "./TagsInput.ts?vue&type=script&lang=ts&"
import style0 from "./styles.scss?vue&type=style&index=0&id=90c36242&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "90c36242",
  null
  
)

export default component.exports