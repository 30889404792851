import { GetterTree } from 'vuex';
import { get } from 'lodash/fp';
import { ILoginState } from './types';
import { IRootState } from '@/store/types';

type UserGetter = GetterTree<ILoginState, IRootState>

const getters: UserGetter = {
  isAuth: get('isAuth'),
  user: get('user'),
  planId: get('user.plan.id'),
  isLoading: get('isLoading'),
  whiteList: get('user.whiteList'),
  blackList: get('user.blackList'),
  accounts: get('accounts')
};

export default getters;
