import { GetterTree } from 'vuex';
import { get } from 'lodash/fp';
import { ITasksState } from './types';
import { IRootState } from '@/store/types';

type TasksGetter = GetterTree<ITasksState, IRootState>

const getters: TasksGetter = {
  tasks: get('tasks'),
  isLoading: get('isLoading'),
  isAdding: get('isAdding'),
  isTaskFetching: get('isTaskFetching'),
  isTaskSaving: get('isTaskSaving'),
  task: get('task'),
};

export default getters;
