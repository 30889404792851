import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action, Getter, namespace } from 'vuex-class';
import TariffItem from './TariffItem.vue';

const GlobalGetter = namespace('global', Getter);
const GlobalAction = namespace('global', Action);

@Component({
  components: {
    TariffItem
  }
})
class Tariffs extends Vue {
  @Prop() upgrade: boolean;
  @Prop() billing: boolean;
  @Prop() showCloseButton: boolean;
  @GlobalAction closeUpgrade: () => void;
  @GlobalGetter isUpgradeOpen: boolean;
  @GlobalGetter startPlan: number;

  created() {
    window.addEventListener('keyup', this.onEscKeyPress);
  }

  beforeDestroy() {
    window.removeEventListener('keyup', this.onEscKeyPress);
  }

  onStop(e: Event) {
    if (this.isUpgradeOpen) {
      e.stopPropagation();
    }
  }

  onEscKeyPress = (evt: KeyboardEvent) => {
    if (evt.keyCode === 27) {
      this.closeUpgrade();
    }
  };
}

export default Tariffs;
