import { Module } from 'vuex';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import { IRootState } from '@/store/types';

import { IGlobalState } from './types';

const state: IGlobalState = {
  isUpgradeOpen: false,
  startPlan: 1,
};

const account: Module<IGlobalState, IRootState> = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};

export default account;
