








import { Component, Vue, Prop } from 'vue-property-decorator';
import { equals } from 'lodash/fp';

@Component
class ResultItem extends Vue {
  @Prop() result: string[];
  @Prop() target: string;
  @Prop() isFollowing: boolean;
  @Prop() isView: boolean;
  isImgLoaded = false;
  get isMedia() {
    const [type] = this.result;
    if (this.isView) {
      return equals('tags', this.target);
    } else {
      return equals('like', type);
    }
  }
  get link() {
    const [, id] = this.result;
    return `https://instagram.com/${this.isMedia ? `p/${id}` : id}`;
  }
  get isLike() {
    return equals('like', this.result[0]);
  }
  get icon() {
    return this.isFollowing
      ? 'fa-minus-circle'
      : this.isLike
      ? 'fa-heart'
      : this.isView
      ? 'fa-eye'
      : 'fa-user';
  }
  get imgUrl() {
    return this.result[2];
  }

  onLoad() {
    setTimeout(() => {
      this.isImgLoaded = true;
    }, 100);
  }
}

export default ResultItem;
