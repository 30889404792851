import axios from 'axios';
import { jsonApi } from './config';

jsonApi.define('account', {
  igm_username: '',
  igm_password: '',
  igmUsername: '',
  igmPassword: '',
  token: '',
  status: '',
  planId: '',
  profilePicUrl: '',
  timezone: '',
  whiteList: '',
  blackList: '',
  groupId: '',
  plan: '',
  verification: '',
  tasks: {
    jsonApi: 'hasMany',
    type: 'tasks'
  }
});

export function createUser(data: {
  igmUsername: string;
  igmPassword: string;
  groupId?: number;
}): Promise<any> {
  return jsonApi.create('account', data);
}

export function getUser(): Promise<any> {
  return jsonApi.find('account', 'current');
}
export function getAccounts(): Promise<any> {
  return jsonApi.findAll('account');
}

export function verifyUser(code: string, id: string, token: string) {
  return axios.get(`https://api.instaswell.com/accounts/checkpoint/code/${code}`, {
    headers: {
      'X-Account-Id': id,
      'X-Account-Token': token,
    }
  });
}

export function twoFactorUser(code: string, id: string, token: string) {
  return axios.get(`https://api.instaswell.com/accounts/two_factor/code/${code}`, {
    headers: {
      'X-Account-Id': id,
      'X-Account-Token': token,
    }
  });
}

export function updateUser(data: any) {
  return jsonApi.update('account', { id: 'current', ...data });
}

export function upgradeAccount(planId: number) {
  const id = localStorage.getItem('id') || '';
  const token = localStorage.getItem('token') || '';
  return axios.get(`https://api.instaswell.com/accounts/upgrade/${planId}`, {
    headers: {
      'X-Account-Id': id,
      'X-Account-Token': token,
    }
  });
}

export function subscribe() {
  const id = localStorage.getItem('id') || '';
  const token = localStorage.getItem('token') || '';
  return axios.get('https://api.instaswell.com/accounts/subscribe', {
    headers: {
      'X-Account-Id': id,
      'X-Account-Token': token,
    }
  });
}
