import { Component, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { IUser } from '@/store/modules/account/types';
import Avatar from '../UI/Avatar/Avatar';

@Component({
  components: {
    Avatar
  }
})
export default class UserMenu extends Vue {
  @Getter('account/user') user: IUser;
  @Action('account/logout') logout: () => void;
  isOpen: boolean = false;

  togglePopup() {
    this.isOpen = !this.isOpen;
  }

  closePopup() {
    setTimeout(() => {
      this.isOpen = false;
    }, 100);
  }
};
