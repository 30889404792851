export const BASE_URL = 'https://api.instaswell.com';
export const ACTIONS = [
  {
    value: 'like',
    icon: 'heart',
  },
  {
    value: 'follow',
    icon: 'user',
  }
];

export const OPTIONS = [
  {
    value: 'not_liked',
    icon: 'eye'
  },
  {
    value: 'not_unfollowed',
    icon: 'user-secret'
  },
  {
    value: 'multilike',
    icon: 'flash'
  },
  {
    value: 'recent_media',
    icon: 'clock-o'
  }
];
