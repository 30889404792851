import JsonApi from 'devour-client';
import { BASE_URL } from '@/constants';

export const jsonApi = new JsonApi({ apiUrl: BASE_URL });

const hasToken = !!localStorage.getItem('token');

const cancellableRequest = {
  name: 'axios-cancellable-request',
  req(payload: any) {
    const api = payload.jsonApi;
    return api.axios(Object.assign(payload.req, {
      cancelToken: new api.axios.CancelToken(function executor(c: any) {
        api.cancel = c;
      })
    }));
  },
};

jsonApi.replaceMiddleware('axios-request', cancellableRequest);

if (hasToken) {
  const id = localStorage.getItem('id') || '';
  const token = localStorage.getItem('token') || '';
  setAuthHeader(id, token);
}

export function setAuthHeader(id: string, token: string) {
  jsonApi.headers['X-Account-Id'] = id;
  jsonApi.headers['X-Account-Token'] = token;
}

export function removeAuthHeader() {
  delete jsonApi.headers['X-Account-Id'];
  delete jsonApi.headers['X-Account-Token'];
}
