// tslint:disable:no-console
import { MutationTree } from 'vuex';
import { remove, findIndex } from 'lodash/fp';
import { ITasksState } from './types';

const mutations: MutationTree<ITasksState> = {
  FETCH_TASKS_REQUEST(state) {
    state.isLoading = true;
  },
  ADD_TASK_REQUEST(state) {
    state.isAdding = true;
  },
  ADD_TASK_SUCCESS(state, task) {
    state.tasks = [task, ...state.tasks];
    state.isAdding = false;
  },
  ADD_TASK_FAILURE(state) {
    state.isAdding = false;
  },
  FETCH_TASKS_SUCCESS(state, data) {
    state.tasks = data;
    state.isLoading = false;
  },
  FETCH_TASKS_FAILURE(state) {
    state.isLoading = false;
  },
  DELETE_TASK_SUCCESS(state, id) {
    state.tasks = remove({ id }, state.tasks);
  },
  UPDATE_TASK_REQUEST(state) {
    state.isTaskSaving = true;
  },
  UPDATE_TASK_SUCCESS(state, task) {
    const idx = findIndex({ id: task.id })(state.tasks);    
    const newTasks = [...state.tasks];
    newTasks[idx] = task;
    state.tasks = newTasks;
    state.isTaskSaving = false;
  },
  UPDATE_TASK_FAILURE(state, error) {
    const idx = findIndex({ id: error.tid })(state.tasks);    
    state.tasks[idx].description = error.message;
    state.isTaskSaving = false;
  },
  FETCH_TASK_REQUEST(state) {
    state.isTaskFetching = true;
  },
  FETCH_TASK_SUCCESS(state, task) {
    state.task = task;
    state.isTaskFetching = false;
  },

  FETCH_TASK_FAILURE(state) {
    state.isTaskFetching = false;
  },
};

export default mutations;
