import { MutationTree } from 'vuex';
import { ILoginState, ILoggedIn, IAccount } from './types';

const mutations: MutationTree<any> = {
  FETCH_LOGIN_REQUEST(state: ILoginState) {
    state.isLoading = true;
  },
  FETCH_LOGIN_SUCCESS(state: ILoginState, data: IAccount) {
    state.user = data;
    state.isAuth = true;
    state.isLoading = false;
  },
  CHECK_POINT_SUCCESS(state: ILoggedIn) {
    state.user.status = 'ok';
    state.isAuth = true;
    state.isLoading = false;
  },
  FETCH_LOGIN_FAILURE(state: ILoginState) {
    state.isLoading = false;
  },
  FETCH_LOGOUT_SUCCESS(state: ILoginState) {
    state.isAuth = false;
    state.user = {};
  },
  UPDATE_ACCOUNT_SUCCESS(state: ILoginState, data: IAccount) {
    state.user = data;
  },
  FETCH_ACCOUNTS_SUCCESS(state: ILoginState, data: IAccount[]) {
    state.accounts = data;
  },
  ADD_ACCOUNT(state: ILoginState, account: IAccount) {
    state.accounts = [account, ...state.accounts];
  }
};

export default mutations
