// tslint:disable:no-console
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { ITask } from '@/types/tasks';
import { map, eq, split, includes } from 'lodash/fp';
import { Action, namespace } from 'vuex-class';
import FollowOptions from '../FollowOptions/FollowOptions';
import UnfollowOptions from '../UnfollowOptions/UnfollowOptions';
import ResultItem from './ResultItem.vue';

const TaskAction = namespace('tasks', Action);
const GlobalAction = namespace('global', Action);

@Component({
  components: {
    FollowOptions,
    UnfollowOptions,
    ResultItem
  }
})
class Task extends Vue {
  @Prop() task: ITask;
  @Prop() activeTask: number;
  @TaskAction deleteTask: () => void;
  @TaskAction updateTask: (task: any) => void;
  @GlobalAction openUpgrade: (startPlan: number) => void;
  tab: string = eq(this.task.id, this.activeTask) ? 'results' : '';
  showMessage: boolean = false;
  isPending: boolean = false;
  description: string = '';

  @Watch('activeTask')
  onActiveChanged(task: number) {
    if (!eq(this.task.id, task)) {
      this.tab = '';
    }
  }

  @Watch('task')
  onResultsChanged(newTask: ITask, oldTask: ITask) {
    if (
      !eq(oldTask.countTotalUnfollow, newTask.countTotalUnfollow) ||
      !eq(oldTask.countTotalFollow, newTask.countTotalFollow) ||
      !eq(oldTask.countTotalLike, newTask.countTotalLike) ||
      !eq(oldTask.countTotalView, newTask.countTotalView)
    ) {
      this.showMessage = false;
    }
    if (oldTask.description !== newTask.description) {
      this.description = newTask.description;
    }
  }

  get showPlayHint() {
    return !this.results.length && !this.running && !this.isPending;
  }

  get running() {
    return eq('running', this.task.status);
  }

  get targetTitle() {
    switch (this.task.target) {
      case 'tags':
        return 'Hashtags';
      case 'locations':
        return 'Locations';
      case 'followers':
        return 'Followers of user(s)';
      case 'following':
        return eq('unfollow', this.task.actions) ? '' : 'Following of user(s)';
      default:
        return 'Hashtags';
    }
  }

  get targetPrefix() {
    switch (this.task.target) {
      case 'tags':
        return 'hashtag';
      case 'locations':
        return 'map-marker';
      case 'followers':
        return 'users';
      case 'following':
        return eq('unfollow', this.task.actions) ? 'minus-circle' : 'users';
      default:
        return 'hashtag';
    }
  }

  get targetText() {
    return this.task.targetText ? this.task.targetText.split(';') : null;
  }
  get taskOptions() {
    return this.task.options.split(';');
  }
  get taskActions() {
    return this.task.actions.split(';');
  }
  get results() {
    return map(split(';'), this.task.results);
  }
  get target() {
    return this.task.target;
  }
  get isView() {
    return eq('view', this.task.actions);
  }
  get isFollowing() {
    return (
      eq('following', this.task.target) && eq('unfollow', this.task.actions)
    );
  }
  get isLikeFollow() {
    return (
      includes('like', this.task.actions) || includes('follow', this.task.actions)
    );
  }
  get isUnfollow() {
    return eq('unfollow', this.task.actions);
  }

  isActiveOption(option: string): boolean {
    return this.task.options.includes(option);
  }

  isActiveAction(action: string): boolean {
    return this.task.actions.includes(action);
  }

  toggleMessage() {
    this.tab = 'results';
    this.showMessage = !this.showMessage;
    this.$emit('setTab', this.task.id);
  }

  setTab(tab: string) {
    this.tab = eq(this.tab, tab) ? '' : tab;
    this.$emit('setTab', this.task.id);
  }

  async onToggleTask() {
    const wasRunning = this.running;
    if (!wasRunning) {
      this.isPending = true;
      this.toggleMessage();
    }
    await this.updateTask({
      id: this.task.id,
      status: this.running ? 'stopped' : 'running'
    });
    this.showMessage = true;
    this.description = this.task.description;
    this.isPending = false;
    if (wasRunning) {
      setTimeout(() => {
        this.showMessage = false;
        this.description = '';
      }, 3000);
    }
  }

  changeTask(value: any) {
    this.updateTask({ id: this.task.id, ...value });
  }

  mounted() {
    if (this.running) {
      this.description = this.task.description;
      this.showMessage = true;
    }
  }
}

export default Task;
