import { render, staticRenderFns } from "./Filters.vue?vue&type=template&id=b48bce6a&scoped=true&"
import script from "./Filters.ts?vue&type=script&lang=ts&"
export * from "./Filters.ts?vue&type=script&lang=ts&"
import style0 from "./styles.scss?vue&type=style&index=0&id=b48bce6a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b48bce6a",
  null
  
)

export default component.exports