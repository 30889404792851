import { render, staticRenderFns } from "./Step2.vue?vue&type=template&id=0deeb5d3&scoped=true&"
import script from "./Step2.ts?vue&type=script&lang=ts&"
export * from "./Step2.ts?vue&type=script&lang=ts&"
import style0 from "./styles.module.scss?vue&type=style&index=0&id=0deeb5d3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0deeb5d3",
  null
  
)

export default component.exports