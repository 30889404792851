import Vue from 'vue';
import Vuex from 'vuex';
import { Deserializer } from 'jsonapi-serializer';
import accountModule from './modules/account';
import tasksModule from './modules/tasks';
import globalModule from './modules/global';
import { camelCase, mapKeys } from 'lodash/fp';

Vue.use(Vuex);

export const deserialize = async (data: any) => {
  let result = null;
  await new Deserializer().deserialize(data, (_: any, res: any) => {
    result = mapKeys(camelCase, res);
  });
  return result;
};

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    account: accountModule,
    tasks: tasksModule,
    global: globalModule,
  },
});
