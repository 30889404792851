import Vue from 'vue'
import VueI18n from 'vue-i18n'
import ru from './locales/ru.json'
import en from './locales/en.json'

Vue.use(VueI18n);

const messages = {
   'ru': ru,
   'en': en
}

let i18n = {} as any;
i18n = new VueI18n({
    locale: window.location.host === 'dashboard.instamanager.ru' ? 'ru' : 'en',
    fallbackLocale: 'en',
    messages
})

export default i18n