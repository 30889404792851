import { MutationTree } from 'vuex';
import { IGlobalState } from './types';

const mutations: MutationTree<IGlobalState> = {
  OPEN_UPGRADE(state, startPlan) {
    state.startPlan = startPlan;
    state.isUpgradeOpen = true;
  },
  CLOSE_UPGRADE(state) {
    state.isUpgradeOpen = false;
  },
};

export default mutations
