import { GetterTree } from 'vuex';
import { get } from 'lodash/fp';
import { IGlobalState } from './types';
import { IRootState } from '@/store/types';

type GlobalGetter = GetterTree<IGlobalState, IRootState>

const getters: GlobalGetter = {
  isUpgradeOpen: get('isUpgradeOpen'),
  startPlan: get('startPlan'),
};

export default getters;
