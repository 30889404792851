
















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
class WaveSpinner extends Vue {
  @Prop({ default: 2 }) count: number;
  @Prop({ default: false }) transparent: boolean;
}

export default WaveSpinner;

