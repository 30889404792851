import Debounce from 'debounce-decorator';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { each } from 'lodash/fp';
import {} from 'googlemaps';
import { fetchLocations } from '@/api/locations';
import TagsInput from '../TagsInput/TagsInput';
// @ts-ignore
import markerIcon from '../../assets/marker.png';
import InfoWindowComponent from './InfoWindow.vue';
import { jsonApi } from '@/api/config';
// @ts-ignore
import MarkerClusterer from './MarkerClusterer.js';

const InfoWindow = Vue.extend(InfoWindowComponent);

interface Location {
  name: string;
  id: number;
  address: string;
  lat: number;
  lng: number;
}

const icon = {
  url: markerIcon,
  scaledSize: new google.maps.Size(38, 52),
  anchor: new google.maps.Point(Math.floor(38 / 2 - 1), 52)
};

@Component({
  components: {
    TagsInput
  }
})
class Locations extends Vue {
  @Prop({ default: () => [] }) tags: Location[];
  @Prop() errors: string[];
  @Prop() clearErrors: () => void;
  @Prop() change: () => void;

  map: google.maps.Map;
  cluster: any = null;
  isLoading: boolean = false;
  isLoaded: boolean = false;
  isEmpty: boolean = false;
  searchQuery: string = '';
  markers: google.maps.Marker[] = [];
  center: { lat: number; lng: number } = { lat: 40.73061, lng: -73.935242 };
  infoWindow: google.maps.InfoWindow = new google.maps.InfoWindow();
  foundLocations: [] = [];
  externalFound: [] = [];

  async mounted() {
    const options: google.maps.MapOptions = {
      zoom: 14,
      center: this.center,
      streetViewControl: false,
      mapTypeControl: false,
      styles: [
        {
          stylers: [{ hue: '#00ffe6' }, { saturation: -20 }]
        },
        {
          featureType: 'road',
          elementType: 'geometry',
          stylers: [{ lightness: 100 }, { visibility: 'simplified' }]
        },
        {
          featureType: 'road',
          elementType: 'labels',
          stylers: [{ visibility: 'off' }]
        }
      ]
    };
    this.map = new google.maps.Map(this.$refs.map as HTMLElement, options);
    if (navigator.geolocation) {
      this.isLoading = true;
      await navigator.geolocation.getCurrentPosition(async ({ coords }) => {
        this.map.setCenter({ lat: coords.latitude, lng: coords.longitude });
        this.center = { lat: coords.latitude, lng: coords.longitude };
        await this.updateMap({ bounds: true });
      }, async () => {
        await this.updateMap({ bounds: true });
      });
    } else {
      await this.updateMap({ bounds: true });
    }
    this.map.addListener('bounds_changed', () => this.updateMap({ bounds: false }));
  }

  @Watch('searchQuery')
  async searchLocations() {
    this.isEmpty = false;
    jsonApi.cancel();
    this.updateMap({ bounds: true });
  }

  @Watch('isLoaded')
  function() {
    this.cluster = new MarkerClusterer(this.map, this.markers,
      {
        maxZoom: 11,
        imagePath: 'http://webloc.ru/m/m'
      });
  }

  @Debounce(500)
  async updateMap({ bounds }: { bounds: boolean }) {
    this.isLoading = true;
    this.isEmpty = false;
    const center = this.map.getCenter();
    jsonApi.cancel();
    const res = await fetchLocations({ lat: center.lat(), lng: center.lng() }, this.searchQuery);
    this.deleteMarkers();
    this.foundLocations = res.data;
    this.externalFound = res.data;
    if (!this.foundLocations.length) {
      this.isLoading = false;
      this.isEmpty = true;
      return false;
    }
    each(({ lat, lng, ...data }: { lat: number; lng: number }) => {
      const position = new google.maps.LatLng(lat, lng);
      const marker = new google.maps.Marker({ position, map: this.map, icon });
      marker.addListener('click', this.clickHandler(marker, data));
      this.markers.push(marker);
    }, this.foundLocations);
    if (this.cluster) {
      this.cluster.addMarkers(this.markers);
    }
    if (bounds) {
      this.setBounds();
    }
    this.isLoading = false;

    setTimeout(() => {
      this.isLoaded = true;
    }, 1500);
  }

  setBounds() {
    const bounds = new google.maps.LatLngBounds();
    each(({ lat, lng }: { lat: number; lng: number }) => {
      const position = new google.maps.LatLng(lat, lng);
      bounds.extend(position);
    }, this.foundLocations);
    this.map.fitBounds(bounds);
  }

  setMapOnAll() {
    each(marker => {
      marker.setMap(null);
    }, this.markers);
  }

  clickHandler = (marker: google.maps.Marker, data: any) => () => {
    this.infoWindow.close();
    const instance = new InfoWindow({
      propsData: {
        name: data.name,
        address: data.address,
      }
    });
    instance.$mount();
    this.infoWindow.setContent(instance.$el.innerHTML);
    this.infoWindow.open(this.map, marker);
  };

  deleteMarkers() {
    this.setMapOnAll();
    each(marker => {
      google.maps.event.clearListeners(marker, 'click');
    }, this.markers);
    this.markers = [];
    if (this.cluster) {
      this.cluster.clearMarkers();
    }
  }

  onLocationsChanged(foundLocations: any) {
    this.foundLocations = foundLocations;
  };

  onQueryChange(query: string) {
    this.searchQuery = query;
  };

}

export default Locations;
