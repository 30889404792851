import { Component, Model, Prop, Vue } from 'vue-property-decorator';

@Component
class Checkbox extends Vue {
  @Prop() name: string;
  @Prop() value: string;
  @Prop({ default: false }) disabled: boolean;

  @Prop()
  @Model('change') checked!: string;

  changed({ target }: { target: HTMLInputElement }) {
    this.$emit('change', target.checked)
  }
}

export default Checkbox;
