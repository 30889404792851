import { Component, Vue, Prop } from 'vue-property-decorator';
import Radio from '../UI/Radio/Radio.vue';
import TagsInput from '../TagsInput/TagsInput';
import { fetchUsers } from '@/api/users';
import Avatar from '../UI/Avatar/Avatar';

interface User {
  username: string,
  id: number,
}

@Component({
  components: {
    Radio,
    TagsInput,
    Avatar
  },
})
class UserList extends Vue {
  @Prop({ default: () => [] }) tags: User[];
  @Prop() target: string;
  @Prop({ default: false }) noTarget: boolean;
  @Prop() label: string;
  @Prop() errors: string[];
  @Prop() clearErrors: () => void;
  @Prop() change: () => void;

  onSetTarget(target: string) {
    this.$emit('changeTarget', { target });
  }

  request = (query: string) => fetchUsers(query);
}

export default UserList;
