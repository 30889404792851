import { ActionTree } from 'vuex';
import { IGlobalState } from './types';
import { IRootState } from '@/store/types';
const page = document.querySelector('.page-body');

const actions: ActionTree<IGlobalState, IRootState> = {
  openUpgrade({ commit }, startPlan = 1) {
    commit('OPEN_UPGRADE', startPlan);
    if (page) {
      page.classList.add('isFixed');
    }
  },
  closeUpgrade({ commit }) {
    commit('CLOSE_UPGRADE');
    if (page) {
      page.classList.remove('isFixed');
    }
  },
};

export default actions;
