import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { IUser } from '@/types/account';
import { equals } from 'lodash/fp';
import UserList from '../UserList/UserList';
import Locations from '../Locations/Locations';
import RadioBtnGroup from '../UI/RadioBtnGroup/RadioBtnGroup';
import TagsInput from '../TagsInput/TagsInput';
import Step2 from './Step2.vue';
import { EditableTask, Target } from '@/types/tasks';
import { fetchTags } from '@/api/hashtags';

@Component({
  components: {
    UserList,
    Step2,
    Locations,
    RadioBtnGroup,
    TagsInput
  }
})
export default class IntroTask extends Vue {
  @Getter('account/user') user: IUser;
  tab: string = 'tags';
  step: number = 1;
  task: EditableTask = {
    target: 'tags',
    options: [],
    actions: ['follow', 'like'],
    filters: {},
    customFilter: []
  };
  errors: string[] = [];

  switchTab(tab: Target) {
    this.tab = tab;
    this.task.target = tab;
    this.clearErrors();
  }
  switchStep(step: number) {
    if (equals('tags', this.tab) && (!this.task.hashtag || !this.task.hashtag.length)) {
      this.errors.push('tags');
      return;
    }
    if (equals('followers', this.tab) && (!this.task.user || !this.task.user.length)) {
      this.errors.push('users');
      return;
    }
    if (equals('locations', this.tab) && (!this.task.location || !this.task.location.length)) {
      this.errors.push('locations');
      return;
    }
    this.step = step;
  }

  changeTask(value: any) {
    this.task = { ...this.task, ...value };
    this.errors = [];
  }

  clearErrors() {
    this.errors = [];
  }

  get tabsNav() {
    return [
      {
        label: 'Hashtags',
        value: 'tags',
      },
      {
        label: 'Competitors',
        value: 'followers',
      },
      {
        label: 'Locations',
        value: 'locations',
      }
    ]
  }

  tagsRequest = (query: string) => fetchTags(query);

}