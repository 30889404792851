














import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/Header/index.vue';
import SvgSprite from '@/components/UI/SvgSprite.vue';
import Tariffs from '@/components/Tariffs/index.vue';
import i18n from './i18n'
import { Action, Getter } from 'vuex-class';
import { equals } from 'lodash/fp';
import { IUser } from '@/types/account';

@Component({
  components: {
    Header,
    SvgSprite,
    Tariffs
  }
})
export default class App extends Vue {
  isLoading: boolean = true;
  @Getter('account/isAuth') isAuth: boolean;
  @Getter('account/user') user: IUser;
  @Getter('global/isUpgradeOpen') isUpgradeOpen: boolean;
  @Action('account/fetchAccount') fetchAccount: () => Promise<any>;
  @Action('global/closeUpgrade') closeUpgrade: () => void;
  @Action('account/logout') logout: () => void;
  get isChecked() {
    return equals('ok', this.user.status);
  }

  async mounted() {
    try {
      document.title = i18n.t('website-name')
      const res = await this.fetchAccount();
      if (
        equals('checkpoint_required', res.data.status) &&
        !equals(this.$route.name, 'code')
      ) {
        this.$router.push('/login');
      }
      if (equals('two_factor_required', res.data.status)) 
      {
        this.$router.push('/two_factor');
      }
      if (equals('login_required', res.data.status)) {
        this.logout()
      }
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
    }
  }
}
