import * as API from '@/api/account';
import { ActionTree } from 'vuex';
import { ILoginState, ISignupUser } from './types';
import { IRootState } from '@/store/types';
import router from '../../../router';
import { removeAuthHeader } from '@/api/config';

const actions: ActionTree<ILoginState, IRootState> = {
  async fetchLogin({ commit }, data: ISignupUser): Promise<any> {
    commit('FETCH_LOGIN_REQUEST');
    try {
      const res = await API.createUser(data);
      commit('FETCH_LOGIN_SUCCESS', res.data);
      localStorage.setItem('token', res.data.token);
      localStorage.setItem('id', res.data.id);
      return res;
    } catch (e) {
      commit('FETCH_LOGIN_FAILURE');
      throw e;
    }
  },
  async fetchAccount({ commit }): Promise<any> {
    try {
      const res = await API.getUser();
      commit('FETCH_LOGIN_SUCCESS', res.data);

      return res;
    } catch (e) {
      commit('FETCH_LOGOUT_SUCCESS');
      localStorage.removeItem('token');
      localStorage.removeItem('id');
      router.replace('/login');
      throw e;
    }
  },
  async fetchAccounts({ commit }) {
    try {
      const res = await API.getAccounts();
      commit('FETCH_ACCOUNTS_SUCCESS', res.data);

      return res;
    } catch (e) {
      throw e;
    }
  },
  addAccount({ commit }, account) {
    commit('ADD_ACCOUNT', account);
  },
  logout({ commit }): void {
    commit('FETCH_LOGOUT_SUCCESS');
    localStorage.removeItem('token');
    localStorage.removeItem('id');
    removeAuthHeader();
    router.replace('/login');
  },
  async updateAccount({ commit }, data) {
    try {
      const res = await API.updateUser(data);
      commit('UPDATE_ACCOUNT_SUCCESS', res.data);

      return res;
    } catch (e) {
      throw e;
    }
  }
};

export default actions;
