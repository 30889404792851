import { jsonApi } from './config';

jsonApi.define('location', {
  lat: '',
  lng: '',
  address: '',
  name: '',
  id: ''
});

export function fetchLocations(
  { lat, lng }: { lat: number; lng: number },
  query: string
) {
  return jsonApi.findAll('location', { lat, lng, query });
}
