import { Module } from 'vuex';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import { IRootState } from '@/store/types';

import { ILoginState } from './types';

const state: ILoginState = {
  user: {},
  isAuth: false,
  isLoading: false,
  accounts: []
};

const account: Module<ILoginState, IRootState> = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};

export default account;
