








import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
class InfoWindow extends Vue{
  @Prop() name: string;
  @Prop() address: string;
}
export default InfoWindow;
