import { Component, Vue, Prop, Model } from 'vue-property-decorator';

@Component
class Radio extends Vue {
  @Prop() name: string;
  @Prop() value: string;
  @Prop() checked: boolean;
  @Prop({ default: false }) isBlock: boolean;
  @Prop({ default: false }) disabled: boolean;

  @Prop()
  @Model('change') modelValue!: string;

  get shouldBeChecked() {
    return this.checked || this.value === this.modelValue;
  }

  changed() {
    this.$emit('change', this.value)
  }
}

export default Radio;
