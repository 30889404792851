import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
class RadioBtnGroup extends Vue {
  @Prop() options: string[];
  @Prop() value: string;
  name = `radioBtnGroup${(Math.random() * 10).toFixed()}`;

  onChange({ target }: { target: HTMLInputElement }) {
    const { value } = target;
    this.$emit('change', value);
  }
}

export default RadioBtnGroup;
