import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { get, find } from 'lodash/fp';
import { ITask } from '@/types/tasks';
import Task from './Task';
import Tariffs from '../Tariffs/index.vue';

@Component({
  components: {
    Task,
    Tariffs
  }
})
class Tasks extends Vue {
  @Getter('tasks/tasks') tasks: ITask[];
  activeTask: number = 0;

  setTab(task: number) {
    this.activeTask = task;
  }

  created() {
    const firstActiveTask = find({ status: 'running' })(this.tasks);
    this.activeTask = firstActiveTask ? get('id')(firstActiveTask) : get('0.id')(this.tasks);
  }
}

export default Tasks;
