




















import { Component, Vue } from 'vue-property-decorator';
import Tasks from '@/components/Tasks/index.vue';
import IntroTask from '@/components/IntroTask/IntroTask';
import { Action, Getter, namespace } from 'vuex-class';
import { ITask } from '@/types/tasks';

const TasksAction = namespace('tasks', Action);
const TasksGetter = namespace('tasks', Getter);

@Component({
  components: {
    Tasks,
    IntroTask,
  }
})
class Dashboard extends Vue {
  @TasksAction fetchTasks: (silent?: boolean ) => Promise<ITask>;
  @TasksGetter isLoading: boolean;
  @TasksGetter tasks: ITask[];
  interval: number;

  pollTasks() {
    this.interval = setInterval(() => this.fetchTasks(true), 10000);
  }

  async mounted() {
    await this.fetchTasks();
    this.pollTasks();
  }

  beforeDestroy() {
    clearInterval(this.interval);
  }
}

export default Dashboard;

