import { jsonApi } from './config';

jsonApi.define('hashtag', {
  id: '',
  name: '',
  searchResultSubtitle: '',
  mediaCount: ''
});

export function fetchTags(query: string) {
  return jsonApi.findAll('hashtag', { query });
}
