import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
class Avatar extends Vue {
  @Prop({ default: '30' }) size: string;
  @Prop(String) src: string;
  @Prop({ default: '' }) name: string;

  get style() {
    return {
      width: `${this.size}px`,
      height: `${this.size}px`,
      minWidth: `${this.size}px`
    };
  }
}

export default Avatar;
