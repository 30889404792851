import { jsonApi } from './config';

jsonApi.define('user', {
  id: '',
  username: '',
  fullName: '',
  isPrivate: '',
  byline: '',
  profilePicUrl: ''
});

export function fetchUsers(username: string) {
  return jsonApi.findAll('user', { username });
}
