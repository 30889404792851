import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import FollowOptions from '../FollowOptions/FollowOptions';
import Filters from '../Filters/Filters';
import { EditableTask, ITask, Tag } from '@/types/tasks';
import { equals, map, set } from 'lodash/fp';

// import Checkbox from '../UI/Checkbox/Checkbox';
// import RadioBtnGroup from '../UI/RadioBtnGroup/RadioBtnGroup';
// import TagsInput from '../TagsInput/TagsInput';

@Component({
  components: {
    FollowOptions,
    Filters,
    // TagsInput,
    // RadioBtnGroup,
    // Checkbox
  }
})
export default class Step2 extends Vue {
  @Prop() switchStep: (step: number) => void;
  @Prop() task: EditableTask;
  @Prop() changeTask: (prop: any) => void;
  @Getter('tasks/isAdding') isAdding: boolean;
  @Action('tasks/addTask') addTask: (task: EditableTask) => Promise<ITask>;
  errors: string[] = [];
  customFilter: boolean = false;
  isExtraOpen: boolean = true;

  onChangeTask(prop: any) {
    this.errors = [];
    this.changeTask(prop);
  }

  clearErrors() {
    this.errors = [];
  }  

  // Filters functionality --->
  onCustomFilterChanged(customFilter: Tag[]) {
    this.task = { ...this.task, ...customFilter };
  }

  onFiltersInput() {
    if (event) {
      const input = event.target as HTMLInputElement
      this.task.filters = 
        set(input.name, input.value)(this.task.filters)
    }
  }

  onFiltersChange() {
    if (event) {
      const checkbox = event.target as HTMLInputElement
      this.task.filters = 
        set(checkbox.name, checkbox.checked ? 'on' : 'off')
          (this.task.filters)
    }
  }
  // Filters functionality <---

  async onSave() {
    if (!this.task.actions.length) {
      this.errors.push('actions');
      return;
    }
    const data: any = {
      ...this.task
    };
    if (equals('tags', data.target)) {
      data.targetText = map('name', data.hashtag);
      data.tags = map('name', data.hashtag);
    }
    if (equals('followers', data.target) || equals('following', data.target)) {
      data.targetText = map('username', data.user);
      data.users = map('id', data.user);
    }
    if (equals('locations', data.target)) {
      data.targetText = map('name', data.location);
      data.locations = map('id', data.location);
    }
    if (data.customFilter.length) {
      data.customFilter = map('name', data.customFilter);
    }
    await this.addTask(data);
  }
}