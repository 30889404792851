import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
class Input extends Vue {
    @Prop({ default: 'text', type: String }) type: string;
    @Prop({ default: '', type: String }) placeholder: string;
    @Prop({ default: '', type: String }) value: string;
    @Prop({ default: '', type: String }) name: string;
    @Prop({ default: false, type: Boolean }) secondary: string;

    input({ target }: { target: HTMLInputElement }) {
      this.$emit('input', target.value);
    }
}

export default Input;
