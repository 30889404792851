import * as API from '@/api/tasks';
import { ActionTree } from 'vuex';
import { ITasksState } from './types';
import { IRootState } from '@/store/types';
import { EditableTask, ITask, STask } from '@/types/tasks';
import { isArray, mapValues } from 'lodash/fp';

const transformData = (task: EditableTask) =>
  mapValues(value => (isArray(value) ? value.join(';') : value), task) as STask;

const tasksActions: ActionTree<ITasksState, IRootState> = {
  async fetchTasks({ commit }, silent): Promise<ITask[]> {
    if (!silent) {
      commit('FETCH_TASKS_REQUEST');
    }
    try {
      const res = await API.getTasks();
      commit('FETCH_TASKS_SUCCESS', res.data);

      return res;
    } catch (e) {
      commit('FETCH_TASKS_FAILURE');
      throw e;
    }
  },
  async deleteTask({ commit }, id) {
    // commit('DELETE_TASK_REQUEST');
    try {
      const res = await API.deleteTask(id);
      commit('DELETE_TASK_SUCCESS', id);

      return res;
    } catch (e) {
      // commit('DELETE_TASK_FAILURE');
      throw e;
    }
  },
  async addTask({ commit }, task) {
    const data = transformData(task);
    commit('ADD_TASK_REQUEST');
    try {
      const res = await API.addTask(data);
      commit('ADD_TASK_SUCCESS', res.data);
      return res;
    } catch (e) {
      commit('ADD_TASK_FAILURE');
      throw e;
    }
  },
  async updateTask({ commit }, task) {
    const data = transformData(task);
    commit('UPDATE_TASK_REQUEST');
    try {
      const res = await API.updateTask(data);
      commit('UPDATE_TASK_SUCCESS', res.data);
      return res;
    } catch (e) { 
      commit('UPDATE_TASK_FAILURE', { tid: task.id, message: e.data.title });
      // throw e;
    }
  },
  async fetchTask({ commit }, id) {
    commit('FETCH_TASK_REQUEST');
    try {
      const res = await API.getTask(id);
      commit('FETCH_TASK_SUCCESS', res.data);
      return res;
    } catch (e) {
      commit('FETCH_TASK_FAILURE');
      throw e;
    }
  }
};

export default tasksActions;
