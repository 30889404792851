import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Debounce from 'debounce-decorator';

import {
  any,
  concat,
  difference,
  each,
  filter,
  includes,
  reject,
  equals
} from 'lodash/fp';

const separators = [' ', '.', ',', ';', '|', '_', '-'];

interface Tag {
  name: string;
  id: number | string;
}

@Component
class TagsInput extends Vue {
  @Prop() title: string;
  @Prop() placeholder: string;
  @Prop() errorPlaceholder: string;
  @Prop({ default: () => [] }) tags: Tag[];
  @Prop({ default: () => [] }) value: Tag[];
  @Prop() errors: string[];
  @Prop() clearErrors: () => void;
  @Prop({ default: false }) paste: boolean;
  @Prop({ default: false }) customTags: boolean;
  @Prop() query: string;
  @Prop() request: (props: any) => any;
  @Prop({ default: () => [] }) externalFound: [];
  @Prop({ default: false }) hideTags: boolean;
  @Prop({ default: false }) reverseAdd: boolean;

  foundTags: [] = [];
  searchQuery: string = '';
  isLoading: boolean = false;
  isReset: boolean = false;
  @Watch('searchQuery')
  @Debounce(500)

  async searchTag(value: string) {
    if (!value) {
      return false;
    }
    this.isLoading = true;
    try {
      this.$emit('queryChange', value);
      if (!this.request) {
        return;
      }
      const res = await this.request(value);
      this.foundTags = res.data;
      this.$emit('searchChange', this.foundTags);
    } catch (e) {
      this.foundTags = [];
      throw e;
    } finally {
      this.isLoading = false;
    }
  }

  toggleTag(tag: Tag) {
    if (this.isAddedTag(tag.id)) {
      this.removeTag(tag.id);
    } else {
      this.addTag(tag);
    }
  }

  isDuplicate = (tag: Tag) => any(({ id }) => equals(id, tag.id), this.tags);

  addTag(tag: Tag) {
    if (this.isDuplicate(tag)) {
      return;
    }
    const tags = this.reverseAdd ? [tag, ...this.tags] : [...this.tags, tag];
    this.onChange(tags);
  }
  
  addTagBySeparator(e: KeyboardEvent) {
    if (separators.includes(e.key)) {
      this.addTagByEnter(e)
    }
  }

  addTagByEnter(e: KeyboardEvent) {
    if (!this.customTags) {
      return;
    }
    e.preventDefault();
    const filteredQuery = this.sanitize(this.searchQuery.toLowerCase())
    const tag = { name: filteredQuery, id: filteredQuery };
    if (this.isDuplicate(tag) || !filteredQuery) {
      return;
    }
    this.addTag(tag);
    this.searchQuery = '';
  }

  onChange(tags: Tag[]) {
    this.$emit('change', { [this.query]: tags });
    this.$emit('input', this.query ? { [this.query]: tags } : tags);
  }

  removeTag(id: number | string) {
    const tags = reject({ id }, this.tags);
    this.onChange(tags);
  }

  isAddedTag(id: number | string) {
    return any({ id }, this.tags);
  }

  invokeDelete() {
    if (this.searchQuery.length > 0) {
      return;
    }
    const lastIndex = this.tags.length - 1;
    const tags = [
      ...this.tags.slice(0, lastIndex),
      ...this.tags.slice(lastIndex + 1)
    ];
    this.onChange(tags);
  }

  addTagsFromPaste() {
    if (!this.paste) {
      return
    }
    setTimeout(() => this.performAddTags(this.searchQuery), 10);
  }
  performAddTags(tag: string) {
    let tags: Tag[];
    tags = this.createTagTexts(tag);
    tags = concat(this.tags, tags);
    tags = difference(tags, this.tags);
    tags = filter(({ name }) => name.trim().length > 0, tags);
    each(t => {
      setTimeout(() => this.addTag(t), 10);
    }, tags);
    this.searchQuery = '';
  }

  createTagTexts(tag: string): Tag[] {
    const regex = new RegExp(separators.map(s => this.quote(s)).join('|'));
    return this.sanitize(tag).split(regex)
      .map(name => ({ name: name.toLowerCase(), id: name.toLowerCase() }));
  }
  quote(regex: string) {
    return regex.replace(/([()[{*+.$^\\|?])/g, '\\$1');
  }
  sanitize(regex: string) {
    return regex.replace(/([`\\\]\['<>?:"{}~!@#$%^&*()+=/\s])/g, '');
  }
  get isError() {
    if (!this.errors) {
      return
    }
    const hasError = this.errors.some(
      e => ['tags', 'users', 'locations'].indexOf(e) >= 0
    )
    if (hasError) {
      this.$scrollTo('#tagsError', 400, {
        offset: -80,
        force: false,
        onDone() {return} // Patch for vue-scrollto
      });
    }
    return hasError;
  }

  @Watch('externalFound')
  onExternalChange(val: any) {
    if (!val) {
      this.isReset = false;
    }
    if (includes('reset', this.externalFound)) {
      this.searchQuery = '';
      this.foundTags = [];
      this.isReset = true;
    } else {
      this.foundTags = this.externalFound;
      this.isReset = false;
    }
  }
}

export default TagsInput;
