import './hooks';
import Vue from 'vue';
import vClickOutside from 'v-click-outside';
import VueChatScroll from 'vue-chat-scroll';
import VueScrollTo from 'vue-scrollto';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n'
import '@/assets/styles/index.scss';

import AppButton from '@/components/UI/AppButton/index.vue';
import Input from '@/components/UI/Input/Input.vue';
import Spinner from '@/components/UI/Spinner/Spinner.vue';
import WaveSpinner from '@/components/UI/WaveSpinner/WaveSpinner.vue';

Vue.component('AppButton', AppButton);
Vue.component('Input', Input);
Vue.component('Spinner', Spinner);
Vue.component('WaveSpinner', WaveSpinner);

Vue.config.productionTip = false;
Vue.use(vClickOutside);
Vue.use(VueScrollTo);
Vue.use(VueChatScroll);
// Vue.use(VueGoogleMaps, {
//   load: {
//     key: 'AIzaSyAHt1zX2oswUGbXkOQolN1Sv1Rn4QhfnxI',
//     libraries: 'places', // This is required if you use the Autocomplete plugin
//     // OR: libraries: 'places,drawing'
//     // OR: libraries: 'places,drawing,visualization'
//     // (as you require)
//
//     //// If you want to set the version, you can do so:
//     // v: '3.26',
//   },
//
//   //// If you intend to programmatically custom event listener code
//   //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
//   //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
//   //// you might need to turn this on.
//   // autobindAllEvents: false,
//
//   //// If you want to manually install components, e.g.
//   //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
//   //// Vue.component('GmapMarker', GmapMarker)
//   //// then disable the following:
//   // installComponents: true,
// })

new Vue({
  i18n,
  store,
  router,
  render: h => h(App)
}).$mount('#app');
