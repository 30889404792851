import { Component, Vue, Prop } from 'vue-property-decorator';
import { ACTIONS, OPTIONS } from '@/constants';
import { Action, Getter } from 'vuex-class';
import { contains } from 'lodash/fp';

@Component
class FollowOptions extends Vue {
  @Getter('account/planId') planId: number;
  @Action('global/openUpgrade') openUpgrade: (startPlan: number) => void;
  @Prop() actions: string[];
  @Prop() options: string[];
  @Prop() short: boolean;
  @Prop() errors: string[];
  @Prop() clearErrors: () => void;
  modelActions: string[] = [];
  modelOptions: string[] = [];

  get actionsList() {
    return ACTIONS;
  }
  get optionsList() {
    return OPTIONS;
  }

  get isError() {
    const hasError = contains('actions', this.errors);
    if (hasError) {
      // @ts-ignore-next-line
      this.$scrollTo('#followError', 400, {
        offset: -80,
        force: false,
        onDone() {return} // Patch for vue-scrollto
      });
    }
    return hasError;
  }

  isDisabled(value: string) {
    switch (value) {
      case 'not_liked':
        return this.planId < 3;
      case 'not_unfollowed':
        return this.planId < 3;
      case 'multilike':
        return this.planId < 2;
      case 'recent_media':
        return this.planId < 2;
      default:
        return  false;
    }
  }

  onChange() {
    this.$emit('change', { actions: this.modelActions, options: this.modelOptions });
  }

  created() {
    this.modelOptions = this.options;
    this.modelActions = this.actions;
  }
}

export default FollowOptions;
