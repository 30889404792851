import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
class Spinner extends Vue {
  @Prop({ default: 'inline' }) type: string;
  @Prop({ default: 'md' }) size: string;

  get classNames() {
    return ['spinner', this.size, this.type];
  }
}

export default Spinner;
