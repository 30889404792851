import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import UserMenu from '../UserMenu/index.vue';
import { IUser } from '@/types/account';

@Component({
  components: {
    UserMenu,
  },
})
export default class Header extends Vue {
  @Action('global/openUpgrade') openUpgrade: (startPlan: number) => void;
  @Getter('account/user') user: IUser;

  get planId() {
    return this.user.planId - 1
  }

  getImgUrl(img: string) {
    return require('../../assets/' + img)
  }
};
