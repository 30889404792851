import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
class AppButton extends Vue {
  @Prop({ default: false }) disabled: boolean;
  @Prop({ default: false }) isLoading: boolean;
  @Prop({ default: false }) inactive: boolean;
  @Prop({ default: 'button' }) type: string;
  @Prop(String) href?: string;
  @Prop({ default: 'primary' }) theme: string;
  @Prop({ default: 'md' }) size: 'sm' | 'md' | 'lg';
  @Prop({ default: false }) isBlock: boolean;
  @Prop({ default: false }) isRounded: boolean;

  get classNames() {
    return [
      'btn',
      this.theme,
      this.size,
      this.isBlock && 'block',
      this.isRounded && 'round',
      this.inactive && 'inactive'
    ];
  }

  onClick(e: MouseEvent) {
    this.$emit('click', e);
  }
}

export default AppButton;
